<template lang="pug">
  .checkboxes-filter
    .checkbox(
      v-for="day in daysOptionsOrder"
      v-if="checkboxes.includes(day)"
      :id="`${randomKey}-${day}`"
      :key="day"
    )
      BTooltip(
        v-if="isTooltipShown(day)"
        :target="`${randomKey}-${day}`"
        delay="0"
        triggers="hover"
        placement="bottom"
      )
        span {{ tooltipTitle }}
      .checkbox-title(:class="day")
        | {{ $t(`inventory_management.inventory_long_period.weekday_filter.${day}`) }}
      AppCheckbox.highlight-checked(
        :class="{ 'invalid': isInvalid(day) }"
        :disabled="inactive || isDisabledCheckbox(day)"
        :value="value[day]"
        @change="handleChange($event, day)"
      )
</template>

<script>
  // misc
  import { randomKey } from "@/helpers/common"
  import { DAYS_OPTIONS_ORDER } from "@/config/constants"

  // components
  import { BTooltip } from "bootstrap-vue"

  export default {
    props: {
      value: {
        type: Object,
        default: () => new Object()
      },
      inactive: {
        type: Boolean,
        default: false
      },
      isDisabled: {
        type: Function,
        default: () => false
      },
      tooltipTitle: {
        type: String,
        default: ""
      },
      isTooltipShown: {
        type: Function,
        default: () => false
      },
      isInvalid: {
        type: Function,
        default: () => false
      }
    },

    components: {
      BTooltip,
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    data() {
      return {
        checkboxes: Object.keys(this.value),
        daysOptionsOrder: DAYS_OPTIONS_ORDER
      }
    },

    computed: {
      randomKey
    },

    methods: {
      handleChange(value, checkbox) {
        const updatedWeekdayValues = { ...this.value, [checkbox]: value }

        this.$emit("input", updatedWeekdayValues)
      },

      isDisabledCheckbox(item) {
        return this.isDisabled(item) && !this.value[item]
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .checkboxes-filter
    align-items: center
    display: flex
    justify-content: space-between

    .checkbox
      &-title
        color: $default-purple
        font-size: 0.8rem
        font-weight: 200
        text-align: center

        &.saturday
          font-weight: 600

        &.sunday
          color: $default-red
          font-weight: 600

      ::v-deep
        .app-checkbox
          padding: 2px 10px 5px 10px
          &-icon
            margin-right: 0
          &.invalid
            .app-checkbox-icon
              +default-invalid-input
</style>
